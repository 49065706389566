import questions from './questions.js';

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

const appReducer = (state, action) => {
  switch (action.type) {
    case "START":
      // Select 5 random questions
      const selectedQuestions = [...questions.sort(() => 0.5 - Math.random()).slice(0, 5)];

      // Randomize the order of the answers
      const preparedQuestions = selectedQuestions.map((q) => {
        return {
          ...q,
          answers: shuffleArray([...q.answers])
        };
      });

      console.log(preparedQuestions);

      return {...state, page: 'question', questions: preparedQuestions, answers: [], currentQuestion: 0};
    case "ANSWER":
      const question = state.questions[state.currentQuestion];
      const answer = question.answers.find((a) => a.id === action.answer);
      const correct = answer.id === question.correctAnswer;
      return {...state, answers: [...state.answers, {question: question.id, answer: answer.id, correct}]};
    case "NEXT_QUESTION":
      return {...state, page: 'question', currentQuestion: state.currentQuestion + 1};
    case "FINISH":
      return {...state, page: 'end'};
    case "RESTART":
      return {...state, page: 'start', answers: []};
    case "TOGGLE_DATAPROTECTION":
      return {...state, showDataProtection: !state.showDataProtection};
    case "TOGGLE_CONDITIONS":
      return {...state, showConditions: !state.showConditions};
    case "TOGGLE_IMPRINT":
      return {...state, showImprint: !state.showImprint};
    default:
      return state;
  }
}

export default appReducer;
