import {useContext} from "react";

import AppContext from "../../../utilities/AppContext/AppContext.jsx";
import Start from "../../pages/Start/Start.jsx";
import Intro from "../../pages/Intro/Intro.jsx";
import Question from "../../pages/Question/Question.jsx";
import End from "../../pages/End/End.jsx";

import video from "../../../assets/video/rms_background_wave_v2.mp4";
import styles from "./Main.module.scss";
import DataProtectionPopup from "../../popups/DataProtectionPopup/DataProtectionPopup.jsx";
import ConditionsPopup from "../../popups/ConditionsPopup/ConditionsPopup.jsx";
import ImprintPopup from "../../popups/ImprintPopup/ImprintPopup.jsx";

const Main = () => {
  const { state, dispatch } = useContext(AppContext);

  return (
    <main className={styles.main}>
      <div className={styles.overlay}></div>
      <video
        className={styles.background}
        src={video}
        autoPlay
        loop
        muted
      ></video>
      { state.page === 'start' ? <Start/> : null }
      { state.page === 'intro' ? <Intro/> : null }
      { state.page === 'question' ? <Question/> : null }
      { state.page === 'end' ? <End/> : null }
      <DataProtectionPopup show={state.showDataProtection} onClose={(e) => {
        e.stopPropagation();
        dispatch({type: 'TOGGLE_DATAPROTECTION'})
      }}/>
      <ConditionsPopup show={state.showConditions} onClose={(e) => {
        e.stopPropagation();
        dispatch({type: 'TOGGLE_CONDITIONS'})
      }}/>
      <ImprintPopup show={state.showImprint} onClose={(e) => {
        e.stopPropagation();
        dispatch({type: 'TOGGLE_IMPRINT'})
      }}/>
    </main>
  );
}

export default Main;